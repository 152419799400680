const Header = ({ walletAddress, connectWallet }) => {
  return (
    <nav className="navbar">
      <div className="container">
        <div className="navbar-brand">
          <h1 className="navbar-item is-size-4">Web3Market - W3Token</h1>
        </div>
        <div id="navbarMenu" className="navbar-menu">
          <div className="navbar-end is-align-items-center">
            <button
              className="button is-white connect-wallet"
              onClick={connectWallet}
            >
              <span className="is-link has-text-weight-bold">
                {walletAddress && walletAddress.length > 0
                  ? `Connected: ${walletAddress.substring(
                      0,
                      6
                    )}...${walletAddress.substring(38)}`
                  : "Connect Wallet"}
              </span>
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
