import { useEffect, useState } from "react";
import "./App.css";
import { ethers } from "ethers";
import faucetContract from "./ethereum/faucet";
import Header from "./Header";

function App() {
  const [walletAddress, setWalletAddress] = useState("");
  const [signer, setSigner] = useState();
  const [fcContract, setFcContract] = useState();
  const [withdrawError, setWithdrawError] = useState("");
  const [withdrawSuccess, setWithdrawSuccess] = useState("");
  const [transactionData, setTransactionData] = useState("");

  // Error Component
  const ErrorComponent = ({ message }) => (
    <div className="message-error">
      ⚠️
      <span>{message}</span>
    </div>
  );

  // Success Component
  const SuccessComponent = ({ message }) => (
    <div className="message-success">
      ✅<span>{message}</span>
    </div>
  );

  useEffect(() => {
    getCurrentWalletConnected();
    addWalletListener();
  }, [walletAddress]);

  const connectWallet = async () => {
    if (typeof window != "undefined" && typeof window.ethereum != "undefined") {
      try {
        /* get provider */
        const provider = new ethers.BrowserProvider(window.ethereum);
        /* get accounts */
        const accounts = await provider.send("eth_requestAccounts", []);
        /* get signer */
        setSigner(await provider.getSigner());
        /* local contract instance */
        setFcContract(faucetContract(provider));
        /* set active wallet address */
        setWalletAddress(accounts[0]);
      } catch (err) {
        console.error(err.message);
      }
    } else {
      /* MetaMask is not installed */
      console.log("Please install MetaMask");
    }
  };

  const getCurrentWalletConnected = async () => {
    if (typeof window != "undefined" && typeof window.ethereum != "undefined") {
      try {
        /* get provider */
        const provider = new ethers.BrowserProvider(window.ethereum);
        /* get accounts */
        const accounts = await provider.send("eth_accounts", []);
        if (accounts.length > 0) {
          /* get signer */
          setSigner(await provider.getSigner());
          /* local contract instance */
          setFcContract(faucetContract(provider));
          /* set active wallet address */
          setWalletAddress(accounts[0]);
        } else {
          console.log("Connect to MetaMask using the Connect Wallet button");
        }
      } catch (err) {
        console.error(err.message);
      }
    } else {
      /* MetaMask is not installed */
      console.log("Please install MetaMask");
    }
  };

  const addWalletListener = async () => {
    if (typeof window != "undefined" && typeof window.ethereum != "undefined") {
      window.ethereum.on("accountsChanged", (accounts) => {
        setWalletAddress(accounts[0]);
      });
    } else {
      /* MetaMask is not installed */
      setWalletAddress("");
      console.log("Please install MetaMask");
    }
  };

  const getOCTHandler = async () => {
    setWithdrawError("");
    setWithdrawSuccess("");
    try {
      const fcContractWithSigner = fcContract.connect(signer);
      const resp = await fcContractWithSigner.requestTokens();
      setWithdrawSuccess("Operation succeeded - enjoy your tokens!");
      setTransactionData(resp.hash);
    } catch (err) {
      setWithdrawError(err.message);
    }
  };

  // Function to extract error message
  const extractErrorMessage = (errorString) => {
    // Check for "execution reverted" pattern
    const executionRevertedMatch = errorString.match(
      /execution reverted: "(.*?)"/
    );
    if (executionRevertedMatch) {
      return executionRevertedMatch[1];
    }

    // Check for "user rejected action" pattern
    if (errorString.includes("user rejected action")) {
      return "User rejected the transaction";
    }

    return null;
  };
  return (
    <div>
      <Header walletAddress={walletAddress} connectWallet={connectWallet} />

      <section className="hero is-fullheight">
        <div className="faucet-hero-body">
          <div className="container has-text-centered main-content">
            <h1 className="title is-1">Faucet on Sepolia</h1>
            <p>
              Fast and reliable - <b>100 W3Token/day</b>
            </p>
            <div className="mt-5">
              {withdrawError && (
                <ErrorComponent message={extractErrorMessage(withdrawError)} />
              )}
              {withdrawSuccess && (
                <SuccessComponent message={withdrawSuccess} />
              )}{" "}
            </div>
            <div className="box address-box">
              <div className="columns">
                <div className="column is-four-fifths">
                  <input
                    className="input is-medium"
                    type="text"
                    value={walletAddress}
                    readOnly
                  />
                </div>
                <div className="column">
                  <button
                    className="button is-link is-medium"
                    onClick={getOCTHandler}
                    disabled={walletAddress ? false : true}
                  >
                    GET TOKENS
                  </button>
                </div>
              </div>
              <article className="panel is-grey-darker">
                <p className="panel-heading">Transaction</p>
                <div className="panel-block">
                  <p>
                    {transactionData ? (
                      <>
                        Transaction hash:{" "}
                        <a
                          href={
                            "https://sepolia.etherscan.io/tx/" + transactionData
                          }
                          target="_blank"
                          rel="noreferrer"
                        >
                          🔗{transactionData}
                        </a>
                      </>
                    ) : (
                      "Please click on GET TOKENS button to get transaction hash..."
                    )}
                  </p>
                </div>
              </article>
            </div>
          </div>
        </div>
      </section>
      <footer className="footer">
        <div className="content has-text-centered">
          <p>© {new Date().getFullYear()} Web3Market. All rights reserved.</p>
          <a
            href="https://www.dmca.com/r/179w9k9"
            title="DMCA.com Protection Status"
            class="dmca-badge"
          >
            <img
              src="https://images.dmca.com/Badges/dmca-badge-w100-5x1-05.png?ID=3b4769c0-4571-4cd5-8848-13de41f07e4b"
              alt="DMCA.com Protection Status"
            />
          </a>
          <script src="https://images.dmca.com/Badges/DMCABadgeHelper.min.js"></script>
          <br />
          Buy our Web3 Products on {" "}
          <a
            href="https://codecanyon.net/user/web3market"
            target="_blank"
            rel="noreferrer"
          >
            CodeCanyon.net
          </a>
        </div>
      </footer>
    </div>
  );
}

export default App;
